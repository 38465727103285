html {
  scroll-behavior: smooth;
}

body {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

a {
  color: #1463c9;
  font-weight: 600;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
